import { get, patch, post } from './api';

export const newTask = async (task, token) => {
  const res = post('/tasks/new', { task }, null, token);

  return res;
};

export const getUserTasks = async (date, token) => {
  const res = get('/tasks/me', { date }, token);

  return res;
};

export const getUnfulfilledDates = async (token) => {
  const res = get('/tasks/unfulfilled-dates', null, token)

  return res
}

export const updateTask = async (taskId, timeInSeconds, token) => {
  const res = patch('/tasks/update', { taskId, timeInSeconds }, null, token);

  return res;
};

export const getActiveTask = async (token) => {
  const res = get('/tasks/activeTask', null, token);

  return res;
};
