import { useContext, useEffect, useState } from 'react';
import { logOutAction } from '../actions/loginActions';
import { LoginContext } from '../context/LoginContext';
import { deleteUserOnCookie } from '../cookies/cookies';
import { getUserCompanies } from '../server/company';
import { getActiveTask, newTask, updateTask } from '../server/task';
import Loader from './Loader';
import AddTaskManualy from './AddTaskManualy';
import ConfirmTime from './ConfirmTime';
import Dropdown from './Dropdown';
import Timer from './Timer';

const assignments = [
  { _id: '1', name: 'Research' },
  { _id: '2', name: 'Wireframes and user flows' },
  { _id: '3', name: 'Meeting with client' },
  { _id: '4', name: 'Working on design concept' },
  { _id: '5', name: 'Design - inner pages & flows' },
  { _id: '6', name: 'Remarks, Notes & Changes' },
];

const TimeTracking = () => {
  const { userData, dispatchUserData } = useContext(LoginContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddTaskManualyOpen, setIsAddTaskManualyOpen] = useState(false);
  const [isConfirmTimeOpen, setIsConfirmTimeOpen] = useState(false);

  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(undefined);

  const [activeTask, setActiveTask] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [projects, setProjects] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedAssignment, setSelectedAssainment] = useState(null);
  const [taskDescripiton, setTaskDescription] = useState('');

  const fetchData = async () => {
    try {
      const companiesData = await getUserCompanies(userData.token);
      setCompanies(
        companiesData.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        )
      );
      const task = await getActiveTask(userData.token);

      if (task) {
        setActiveTask(task);

        if (task.isActive) {
          setIsRunning(true);
          setIsDisabled(true);
          setSeconds(
            Math.floor(Date.now() / 1000) - Math.floor(task.startTime / 1000)
          );
        }
      }

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (activeTask && companies && companies.length) {
      handleSelectCompany(activeTask.company);

      const assignment = assignments.find(
        (el) => el.name === activeTask.assignmentType
      );

      if (assignment) {
        handleSelectedAssignment(assignment._id);
      }
      // setTaskDescription(activeTask.taskDescription);
    }
  }, [activeTask, companies]);

  useEffect(() => {
    if (activeTask && projects && projects.length) {
      handleSelectProject(activeTask.project);
    }
  }, [activeTask, projects]);

  // useEffect(() => {
  //   if (selectedCompany && selectedCompany.projects.length === 1) {
  //     setSelectedProject(selectedCompany.projects[0]);
  //   } else {
  //     setSelectedProject(null);
  //   }
  // }, [selectedCompany]);

  const onClickStartTimer = () => {
    setIsRunning(true);
    setIsDisabled(true);
    setIsAddTaskManualyOpen(false);

    const data = {
      taskType: 'design',
      user: userData.user._id,
      company: selectedCompany._id,
      project: selectedProject._id,
      assignmentType: selectedAssignment.name,
      taskDescription: taskDescripiton.trim(),
      date: new Date(),
      startTime: Date.now(),
      isActive: true,
    };

    newTask(data, userData.token)
      .then((task) => {
        setActiveTask(task);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectCompany = (companyId) => {
    const theCompany = companies.find((company) => company._id === companyId);

    if (theCompany) {
      setProjects([]);

      const favoriteProjectIds = userData.user.favorites?.projects || [];
      let noneFavoriteProjects = [];

      let favoriteProjects = theCompany.projects.filter((project) => {
        if (project.isArchived === true) return false;

        if (favoriteProjectIds.includes(project._id)) {
          return true;
        }

        noneFavoriteProjects.push(project);
      });

      noneFavoriteProjects = noneFavoriteProjects.sort((a, b) => {
        const aCompanyName = a.name.toLowerCase();
        const bCompanyName = b.name.toLowerCase();
        return aCompanyName > bCompanyName ? 1 : -1;
      });

      favoriteProjects = favoriteProjects.sort((a, b) => {
        const aCompanyName = a.name.toLowerCase();
        const bCompanyName = b.name.toLowerCase();
        return aCompanyName > bCompanyName ? 1 : -1;
      });

      const noneArchivedProjects = [
        ...favoriteProjects,
        ...noneFavoriteProjects,
      ].filter((project) => !project.isArchived);

      setSelectedCompany(theCompany);
      setProjects(noneArchivedProjects);

      if (noneArchivedProjects.length === 1) {
        handleSelectProject(noneArchivedProjects[0]._id);
      }
    }
  };

  const handleSelectProject = (projectId) => {
    const theProject = projects.find((project) => project._id === projectId);
    setSelectedProject(theProject);
  };

  const handleSelectedAssignment = (assignmentId) => {
    const theAssignment = assignments.find(
      (assignment) => assignment._id === assignmentId
    );
    setSelectedAssainment(theAssignment);
  };

  // const resetForm = () => {
  //   setSelectedCompany(null);
  //   setSelectedProject(null);
  //   setSelectedAssainment(null);
  //   setTaskDescription('');
  // };

  const onClickAddTaskManualy = (date, timeInSeconds) => {
    setIsAddTaskManualyOpen(false);
    setIsSubmiting(true);

    const data = {
      taskType: 'design',
      user: userData.user._id,
      company: selectedCompany._id,
      project: selectedProject._id,
      assignmentType: selectedAssignment.name,
      taskDescription: taskDescripiton.trim(),
      startTime: Date.now(),
      date,
      timeInSeconds,
      isActive: false,
      isAddedManualy: true,
    };

    newTask(data, userData.token)
      .then((task) => {
        setIsSuccess(true);
      })
      .catch((err) => {
        setIsSuccess(false);
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setIsSubmiting(false);
          setIsSuccess(undefined);
        }, 1500);
      });

    // resetForm();
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    if (!selectedCompany && !selectedProject && !selectedAssignment) return;

    setIsConfirmTimeOpen(true);
  };

  const saveTask = (res) => {
    updateTask(activeTask._id, res + (seconds % 60), userData.token)
      .then(() => {
        // resetForm();
        setIsRunning(false);
        setIsDisabled(false);
        setSeconds(0);
        setActiveTask(null);
        setIsSuccess(true);
      })
      .catch((err) => {
        setIsSuccess(false);
        console.log(err);
      })
      .finally(() => {
        setTimeout(() => {
          setIsSubmiting(false);
          setIsSuccess(undefined);
        }, 2000);
      });
  };

  const onClickLogout = () => {
    dispatchUserData(logOutAction());
    deleteUserOnCookie();
  };

  return (
    <div className="time-tracking">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="forms-wrapper">
          <form onSubmit={onSubmitForm}>
            <Dropdown
              title="Company"
              list={companies}
              placeholder="Client..."
              selectedValue={selectedCompany}
              onChange={handleSelectCompany}
              isDisabled={isDisabled}
            />
            <Dropdown
              title="Porject"
              list={projects.filter((project) => !project.isArchived)}
              placeholder="Project..."
              selectedValue={selectedProject}
              onChange={handleSelectProject}
              isDisabled={isDisabled}
            />
            <Dropdown
              title="Assignment type"
              list={assignments}
              placeholder="Task..."
              selectedValue={selectedAssignment}
              onChange={handleSelectedAssignment}
              isDisabled={isDisabled}
            />
            <div className="dropdown">
              <textarea
                onChange={(e) => setTaskDescription(e.target.value)}
                value={taskDescripiton}
                disabled={isDisabled}
                placeholder="Extra text..."
                name="description"
                id="description"
                cols="30"
                rows="4"
              />
            </div>
            <div className="timers-wrapper">
              {!isConfirmTimeOpen && (
                <Timer
                  seconds={seconds}
                  setSeconds={setSeconds}
                  isRunning={isRunning}
                  onClickStartTimer={onClickStartTimer}
                  isDisabled={
                    isDisabled ||
                    !(selectedCompany && selectedProject && selectedAssignment)
                  }
                  isSubmiting={isSubmiting}
                  isSuccess={isSuccess}
                />
              )}
            </div>
          </form>

          {isConfirmTimeOpen && (
            <ConfirmTime
              timeInSeconds={seconds}
              setIsConfirmTimeOpen={setIsConfirmTimeOpen}
              setIsSubmiting={setIsSubmiting}
              saveTask={saveTask}
            />
          )}

          <div className="add-task-wrapper">
            <div className="add-task">
              <button
                disabled={seconds > 0}
                onClick={() => setIsAddTaskManualyOpen(!isAddTaskManualyOpen)}
              >
                {isAddTaskManualyOpen ? '- Close' : '+ Add'} manual
              </button>
              <button className="logout" onClick={onClickLogout}>
                Logout
              </button>
            </div>
          </div>
          {isAddTaskManualyOpen && (
            <AddTaskManualy
              isDisabled={
                isDisabled ||
                seconds > 0 ||
                !(selectedCompany && selectedProject && selectedAssignment)
              }
              onClickAddTaskManualy={onClickAddTaskManualy}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TimeTracking;
